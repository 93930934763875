import { ArrowLeft } from '@grupo-sbf/motriz-icons';
import { Link as Anchor } from '@grupo-sbf/motriz-nike';
import Link from 'next/link';
import { useRouter } from 'next/router';
import Button from '@/src/common/components/Button/Button';
import Container from '@/src/common/components/Container';
import Logo from '@/src/common/components/Logo';
import { getCoreRoutes } from '@/src/common/config';
import {
  Wrapper,
  HeaderBox,
  ReturnIcon,
  BoxIcon,
  LinksBox,
  SubHeader as StyledSubHeader,
  SubHeaderIcon,
} from './Header.styled';

const Header = () => {
  const coreRoutes = getCoreRoutes();
  const router = useRouter();
  const isHomePage = !(router.pathname == coreRoutes.home);

  return (
    <>
      <StyledSubHeader>
        <SubHeaderIcon>
          <ArrowLeft />
          <Link href={coreRoutes.nike} passHref legacyBehavior>
            <Anchor size="small">Voltar para nike.com.br</Anchor>
          </Link>
        </SubHeaderIcon>

        <LinksBox>
          <Link href={coreRoutes.attendance} passHref legacyBehavior>
            <Anchor size="small">Ajuda</Anchor>
          </Link>
        </LinksBox>
      </StyledSubHeader>

      <HeaderBox data-testid="header">
        <Container maxWidth="1920px">
          <Wrapper>
            {isHomePage && (
              <BoxIcon>
                <Button
                  size="small"
                  variant="secondary"
                  icon={<ReturnIcon />}
                  title="Voltar página"
                  onClick={() => router.back()}
                />
              </BoxIcon>
            )}
            <Logo
              src="/images/brands/logo.svg"
              href={coreRoutes.home}
              width={64}
              height={23}
              alt="Nike"
              title="Ir para a página principal"
            />
          </Wrapper>
        </Container>
      </HeaderBox>
    </>
  );
};

export default Header;
