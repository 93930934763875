import { Facebook, Instagram, Youtube } from '@grupo-sbf/motriz-icons';
import Link from 'next/link';
import { getCoreRoutes } from '@/src/common/config';
import { SectionTitle } from '@/src/common/patterns/Footer/Footer.styled';
import { SocialGrid, SocialWrapper } from './Social.styled';

const coreRoutes = getCoreRoutes();

const socialMedia = {
  facebook: 'Facebook',
  instagram: 'Instagram',
  youtube: 'Youtube',
};

const Social = () => {
  return (
    <SocialWrapper data-testid="footer-social">
      <SectionTitle>Redes sociais</SectionTitle>

      <SocialGrid>
        <Link href={coreRoutes.facebook} passHref legacyBehavior>
          <a target="_blank" href="passRef" title={socialMedia.facebook}>
            <Facebook />
          </a>
        </Link>

        <Link href={coreRoutes.instagram} passHref legacyBehavior>
          <a target="_blank" href="passRef" title={socialMedia.instagram}>
            <Instagram />
          </a>
        </Link>

        <Link href={coreRoutes.youTube} passHref legacyBehavior>
          <a target="_blank" href="passRef" title={socialMedia.youtube}>
            <Youtube />
          </a>
        </Link>
      </SocialGrid>
    </SocialWrapper>
  );
};

export default Social;
