import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Link as Anchor,
  DesktopView,
  MobileView,
} from '@grupo-sbf/motriz-nike';
import Link from 'next/link';
import { AnchorHTMLAttributes, memo } from 'react';
import {
  SectionTitle,
  VerticalGrid,
} from '@/src/common/patterns/Footer/Footer.styled';
import {
  AccordionTitle,
  ClosedIcon,
  ExpandedIcon,
} from './CollapsibleLinks.styled';

type LinkCollapsi = {
  text: string;
  href: string;
} & AnchorHTMLAttributes<HTMLAnchorElement>;

type CollapsibleLinksProps = {
  id: string;
  title: string;
  links: LinkCollapsi[];
};

const CollapsibleLinks = ({ id, title, links }: CollapsibleLinksProps) => {
  const grid = (
    <VerticalGrid size="small">
      {links.map(({ text, href, ...linkProps }) => (
        <Link key={text} href={href} passHref legacyBehavior>
          <Anchor {...linkProps} size="small">
            {text}
          </Anchor>
        </Link>
      ))}
    </VerticalGrid>
  );

  return (
    <div data-testid={id}>
      <MobileView>
        <Accordion>
          <AccordionSummary
            expandedIcon={<ExpandedIcon />}
            closedIcon={<ClosedIcon />}
            aria-controls={`${id}-content`}
            id={`${id}-header`}
          >
            <AccordionTitle>{title}</AccordionTitle>
          </AccordionSummary>
          <AccordionDetails>{grid}</AccordionDetails>
        </Accordion>
      </MobileView>
      <DesktopView>
        <SectionTitle>{title}</SectionTitle>
        {grid}
      </DesktopView>
    </div>
  );
};

export default memo(CollapsibleLinks);
