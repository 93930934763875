import NextImg, { ImageProps } from 'next/legacy/image';
import { useState } from 'react';
import { PRODUCT_IMAGE_FALLBACK } from './constants';

export type ImgProps = ImageProps & {
  alt: string;
  srcOnError?: string;
};

const Img = ({
  src,
  srcOnError = PRODUCT_IMAGE_FALLBACK,
  loading = 'lazy',
  priority,
  placeholder = 'empty',
  blurDataURL = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mPstAcAAVcAyrKrMM4AAAAASUVORK5CYII=',
  ...props
}: ImgProps) => {
  const [imageError, setImageError] = useState(false);
  const imgSrc = imageError ? srcOnError : src;

  const handleOnError = () => {
    setImageError(true);
  };

  const priorityLoad = priority ? { priority } : { loading };

  const controlBlur =
    placeholder === 'blur'
      ? {
          placeholder,
          blurDataURL,
        }
      : { placeholder };

  return (
    <NextImg
      src={imgSrc}
      onError={handleOnError}
      {...priorityLoad}
      {...controlBlur}
      {...props}
    />
  );
};

export * from 'next/legacy/image';

export default Img;
