import StaticImage from '@/src/common/components/Image';
import { SectionTitle } from '@/src/common/patterns/Footer/Footer.styled';
import {
  PaymentMethod,
  PaymentsGrid,
  PaymentsWrapper,
} from './Payments.styled';

const Payments = () => {
  return (
    <PaymentsWrapper data-testid="footer-payments">
      <SectionTitle>Formas de pagamento</SectionTitle>

      <PaymentsGrid>
        <PaymentMethod>
          <StaticImage
            src="/images/paymentMethods/mastercard.svg"
            alt="Mastercard"
            width={35}
            height={22}
            disablePlaceholder
          />
        </PaymentMethod>

        <PaymentMethod>
          <StaticImage
            src="/images/paymentMethods/visa.svg"
            alt="Visa"
            width={40}
            height={16}
            disablePlaceholder
          />
        </PaymentMethod>

        <PaymentMethod>
          <StaticImage
            src="/images/paymentMethods/amex.svg"
            alt="Amex"
            width={27}
            height={20}
            disablePlaceholder
          />
        </PaymentMethod>

        <PaymentMethod>
          <StaticImage
            src="/images/paymentMethods/elo.svg"
            alt="Elo"
            width={22}
            height={22}
            disablePlaceholder
          />
        </PaymentMethod>

        <PaymentMethod>
          <StaticImage
            src="/images/paymentMethods/hipercard.svg"
            alt="Hipercard"
            width={38}
            height={16}
            disablePlaceholder
          />
        </PaymentMethod>

        <PaymentMethod>
          <StaticImage
            src="/images/paymentMethods/discover.svg"
            alt="Discover"
            width={37}
            height={10}
            disablePlaceholder
          />
        </PaymentMethod>
      </PaymentsGrid>
    </PaymentsWrapper>
  );
};

export default Payments;
