import Link from 'next/link';
import StaticImage from '@/src/common/components/Image';
import { LogoAnchor } from './Logo.styled';

type LogoPropsType = {
  alt: string;
  height: number;
  href: string;
  src: string;
  title: string;
  width: number;
};

const Logo = ({ alt, height, href, src, title, width }: LogoPropsType) => {
  return (
    <Link href={href} passHref legacyBehavior>
      <LogoAnchor href="passHref" title={title}>
        <StaticImage
          src={src}
          alt={alt}
          width={width}
          height={height}
          disablePlaceholder
        />
      </LogoAnchor>
    </Link>
  );
};

export default Logo;
